@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500&display=swap");

#root {
 
  margin: 0 auto;
  
  text-align: center;
  font-family: "Poppins", sans-serif;
}

h2 {
  font-family: "Lato", sans-serif;
}

.app-main {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
    
}

.app-input {
  height: 35px;
  margin: 20px;
  border: 2px solid black;
  padding: 10px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.result-image {
  margin-top: 20px;
  width: 350px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}